import React, { useContext } from "react"
import "../styles/styles.css"
import { graphql } from "gatsby"
import { LayoutBootstrap } from "./layoutBootstrap"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Row, Col } from "react-bootstrap"
import { LanguageContext } from "./language"
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import { useEffect } from "react"


// const shortcodes = { Link } // Provide common components here

export default function LayoutPost ({ data }) {
const {lang, setLang} = useContext(LanguageContext);
var posts = data.allMdx.edges.filter(
    e=>e.node.frontmatter.date === data.mdx.frontmatter.date && e.node.frontmatter.language === lang);
//FIXME: If there are ever two news posts on the same date, this will be problematic!!!! Edit this space


return(
    <LayoutBootstrap>
       {/* Featured Image */}
        <Container fluid style={{backgroundColor:"#6ba7cf"}}>
          <Row className="no-gutters">
            <Col style={{padding:0, display: 'grid'}}>
              <GatsbyImage
                image={getImage(data.mdx.frontmatter.featuredImage)} 
                loading="eager"
                className="image" 
                style={{gridArea:'1/1'}}
                imgStyle={{quality:100}}
                alt = "newspicture"
              />
            </Col>
          </Row>
        </Container>

      {/* Body */}
       <Container>
        <Row>
          <Col className="bodytext">
            <h1 className="bodytext">{posts[0].node.frontmatter.title}</h1>
            <div className="mdx">
            <MDXProvider> 
                <MDXRenderer frontmatter={posts[0].node.frontmatter}>{posts[0].node.body}</MDXRenderer>
            </MDXProvider>
            </div>
          </Col>
        </Row>
      </Container>
    </LayoutBootstrap>
)
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1280, height: 350)
          }
        }
      }
    }
    allMdx {
      edges {
        node {
          frontmatter {
            title
            date
            language
          }
          body
        }
      }
    }
  }
`